import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const colorsAndIconStatuses = {
    DRAFT: { background: '#74798C1A', textColor: '#757A8C', icon: 'refresh' },
    SENT: { background: '#1D34951A', textColor: '#1D3495', icon: 'check_circle_outline' },
    SIGNED: { background: '#EAF7F7', textColor: '#31AEAC', icon: 'check_circle_outline' },
    COMPLETED: { background: '#4D93E31A', textColor: '#176AC7', icon: 'check_circle_outline' },
    CONFIRMED: { background: '#EAF7F7', textColor: '#31AEAC', icon: 'check_circle_outline' },
    FINALIZED: { background: '#EAF7F7', textColor: '#31AEAC', icon: 'check_circle_outline' },
    PAID: { background: '#EAF7F7', textColor: '#31AEAC', icon: 'check_circle_outline' },
    EXPIRED: { background: '#E465261A', textColor: '#E46526', icon: 'error_outline' },
    REFUSED: { background: '#E465261A', textColor: '#E46526', icon: 'error_outline' },
    SIGNED_AND_FINALIZED_RELATED_INVOICE: { background: '#1D34951A', textColor: '#1D3495', icon: 'check_circle_outline' },
    REJECTED: { background: '#E465261A', textColor: '#E46526', icon: 'error_outline' },
    CANCELLED: { background: '#E465261A', textColor: '#E46526', icon: 'error_outline' },
    RECTIFIED: { background: '#E2B84426', textColor: '#BA9839', icon: 'refresh' },
    PROCESSING: { background: '#E2B84426', textColor: '#BA9839', icon: 'schedule' },
    SENT_FOR_BACK_OFFICE_VERIFICATION: { background: '#1D34951A', textColor: '#1D3495', icon: 'check_circle_outline' },
    CANCELLED_FROM_BACK_OFFICE: { background: '#E465261A', textColor: '#E46526', icon: 'error_outline' },
    UNVERIFIED: { background: '#74798C1A', textColor: '#757A8C', icon: 'radio_button_unchecked' },
    PENDING: { background: '#E2B84426', textColor: '#BA9839', icon: 'schedule' },
    WITHOUT_VERIFICATION: { background: '#74798C1A', textColor: '#757A8C', icon: 'radio_button_unchecked' },
    ACCEPTED: { background: '#EAF7F7', textColor: '#31AEAC', icon: 'check_circle_outline' },
    Éligible: { background: '#EAF7F7', textColor: '#31AEAC', icon: 'check_circle_outline' },
    'Non éligible': { background: '#E465261A', textColor: '#E46526', icon: 'error_outline' },
    'Création du compte en cours': { background: '#74798C1A', textColor: '#757A8C', icon: 'check_circle_outline' },
    "En cours d'instruction par l'ANAH": { background: '#E2B84426', textColor: '#BA9839', icon: 'schedule' },
    "Demande validée par l'ANAH": { background: '#EDF4FC', textColor: '#357CCE', icon: 'check_circle_outline' },
    'Demande de solde réalisée': { background: '#E9E5F5', textColor: '#8C76C9', icon: 'check_circle_outline' },
    'RÉNOLIB prépare votre paiement': { background: '#E9E5F5', textColor: '#8C76C9', icon: 'check_circle_outline' },
    'En attente du devis signé': { border: '1px solid #74798c', background: '#FFFFFF', textColor: '#74798c', icon: 'check_circle_outline' },
    'Contrôle en cours': { background: '#E9E5F5', textColor: '#3E60AC', icon: 'check_circle_outline' },
    'Mandat refusé': { background: '#E465261A', textColor: '#E46526', icon: 'error_outline' },
    'Demande refusée': { background: '#E465261A', textColor: '#E46526', icon: 'error_outline' },
    "Payé par l'ANAH": { background: '#EAF7F7', textColor: '#6CBCC9', icon: 'check_circle_outline' },
    'Payé par RÉNOLIB': { background: '#EAF7F7', textColor: '#6CBCC9', icon: 'check_circle_outline' }
};

function getColorAndIconStatus(status) {
    if (Object.keys(colorsAndIconStatuses).includes(status)) return colorsAndIconStatuses[status];

    return { background: '#74798C1A', textColor: '#757A8C', icon: 'radio_button_unchecked' };
}

const UnStyledStatus = ({ className, children, status, testId, isPast = false }) => {
    const { icon } = getColorAndIconStatus(status);

    return (
        <div className={className} data-testid={testId}>
            <div>
                <span className='material-icons-outlined'>{icon}</span>
                <span>{children}</span>
            </div>
        </div>
    );
};

const StyledStatus = styled(UnStyledStatus)`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 105px;
    height: max-content;
    line-height: normal;
    padding: 5px;
    border-radius: 5px;
    font-weight: 500;
    background-color: ${(props) => getColorAndIconStatus(props.status, { isPast: props.isPast, isFutur: props.isFutur }).background};
    color: ${(props) => getColorAndIconStatus(props.status, { isPast: props.isPast, isFutur: props.isFutur }).textColor};
    border: ${(props) => getColorAndIconStatus(props.status, { isPast: props.isPast, isFutur: props.isFutur }).border};
    > div {
        display: flex;
        align-items: center;
        > span:nth-child(1) {
            font-size: 16px;
            margin-right: 4px;
        }
    }
`;
function Status(props) {
    return <StyledStatus {...props} />;
}

Status.propTypes = {
    status: PropTypes.oneOf(Object.keys(colorsAndIconStatuses)),
    testId: PropTypes.string
};

export default Status;
