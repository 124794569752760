const colors = ['primary', 'secondary', ''];
const lightModes = ['0', '1', '2', '3', '4', ''];

const organizationDocumentStatusTranslations = {
    PENDING: 'En cours de vérification',
    CONFIRMED: 'Confirmé',
    REJECTED: 'Rejeté',
    EXPIRED: 'Expiré',
    CANCELLED_FROM_BACK_OFFICE: 'Annulé'
};

const pmCustomerActivities = {
    SOCIAL_LANDLORD: 'Bailleur social',
    COOWNED_SYNDICATE: 'Syndic de copropriété'
};

const renovationAddressHousingTypes = {
    INDIVIDUAL_HOUSE: 'Maison individuelle',
    APARTMENT: 'Appartement(s)'
};

const renovationAddressActivityAreas = {
    OFFICES: 'Bureaux',
    EDUCATION: 'Enseignement',
    COMMERCES: 'Commerces',
    HOTELS_CATERING: 'Hôtellerie-Restauration',
    HEALTH: 'Santé',
    OTHERS: 'Autres secteurs'
};

const renovationAddressBuildingSectors = {
    RESIDENTIAL: 'Résidentiel',
    TERTIARY: 'Tertiaire'
};

const renovationAddressBuildingSectorsEnum = Object.freeze({
    RESIDENTIAL: 'RESIDENTIAL',
    TERTIARY: 'TERTIARY'
});

const uploadedDocumentStatuses = Object.freeze({
    PENDING: 'PENDING',
    CONFIRMED: 'CONFIRMED',
    REJECTED: 'REJECTED'
});

const warrantyAreas = Object.freeze({
    WHOLE_FRANCE: 'France entière',
    METROPOLITAN_FRANCE: 'France métropolitaine',
    DOM_TOM: 'DOM-TOM'
});

const customerTypesEnum = Object.freeze({
    CUSTOMER_PP: 'CUSTOMER_PP',
    CUSTOMER_PM: 'CUSTOMER_PM'
});

const customerPMActivitiesEnum = {
    SOCIAL_LANDLORD: 'SOCIAL_LANDLORD',
    COOWNED_SYNDICATE: 'COOWNED_SYNDICATE',
    OTHER: 'OTHER'
};

export const sortingOrders = Object.freeze({
    ASCEND: 'ASCEND',
    DESCEND: 'DESCEND'
});

export const genders = Object.freeze({
    MALE: 'MALE',
    FEMALE: 'FEMALE'
});

export const housingSituations = Object.freeze({
    GREAT_PRECARITY: 'GREAT_PRECARITY',
    PRECARITY: 'PRECARITY',
    CLASSIC: 'CLASSIC'
});

export const translationModes = Object.freeze({
    SHORT: 'SHORT',
    LONG: 'LONG'
});

export const maPrimeRenovLabels = Object.freeze({
    BLUE: 'Bleu',
    YELLOW: 'Jaune',
    PURPLE: 'Violet',
    PINK: 'Rose'
});

export default {
    colors,
    lightModes,
    organizationDocumentStatusTranslations,
    pmCustomerActivities,
    renovationAddressHousingTypes,
    renovationAddressActivityAreas,
    renovationAddressBuildingSectors,
    uploadedDocumentStatuses,
    warrantyAreas,
    renovationAddressBuildingSectorsEnum,
    customerTypesEnum,
    customerPMActivitiesEnum,
    maPrimeRenovLabels
};
